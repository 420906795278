import React from "react"
import styled from "styled-components"

export const StyledSection = styled.div`
  p {
    font-size: 24px;
    text-align: center;
    margin-top: -4rem;
    padding-bottom: 5rem;
  }
  @media only screen and (max-width: 900px) {
  }
`

export default function Temp(props) {
  return (
    <StyledSection>
      <p>We are adding data..</p>
    </StyledSection>
  )
}
