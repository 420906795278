import React from "react"
import ImageCover from "../components/ImageCover"
import Layout from "../components/layout"
import RegionGrid from "../components/RegionGrid/RegionGrid"
// import { GetRandom } from "../hooks/use-random-images"
import { graphql } from "gatsby"
import Temp from "../components/AddingData"

import Seo from "../components/seo"

export default function SpainWines({ data }) {
  //console.log(data.allStrapiRegions.nodes)
  const wines = data.allStrapiRegions.nodes[0].domaines.map(e => ({
    title: e.Domaine,
    slug: e.Slug,
    image: e.MainImage?.localFile?.childImageSharp?.gatsbyImageData,
    // ? e.Image.childImageSharp.fixed
    // : null,
  }))

  wines.sort(function (a, b) {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  })
  // const { RandomImages } = GetRandom()
  // const image = RandomImages[Math.floor(Math.random() * RandomImages.length)]

  const mainImage =
    data.allStrapiRegions.nodes[0].Image.localFile.childImageSharp
      .gatsbyImageData

  return (
    <Layout relative={false}>
      <Seo title="Italian Wine Selection" />
      <ImageCover
        image={mainImage}
        heading={"Spain"}
        section={"#wines"}
        third
        truedat={true}
      />
      <RegionGrid bigData={wines} />
      {wines.length === 0 && <Temp />}
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiRegions(filter: { country: { eq: "Spain" } }) {
      nodes {
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 60)
            }
          }
        }
        domaines {
          Slug
          Domaine
          MainImage {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 300, quality: 60, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
